const config = {
    firebaseConfig : {
        apiKey: "AIzaSyDzmE7u_oLB9JrnzgGamtaL5TtN0eqJyZw",
        authDomain: "choira-465fd.firebaseapp.com",
        projectId: "choira-465fd",
        storageBucket: "choira-465fd.appspot.com",
        messagingSenderId: "1040921488677",
        appId: "1:1040921488677:web:9be8495fb7eae58d08fecc"
    }
}

export default config